<template>
    <div>
        <div class="header-wrapper">
            <app-header :opaque="true" />
        </div>

        <section class="tours-section">
            <slot />
        </section>

        <app-footer />
    </div>
</template>

<script>
    import AppHeader from '@/components/Header.vue'
    import AppFooter from '@/components/Footer.vue'

    export default {
        components: {
            AppHeader,
            AppFooter
        }
    }
</script>

<style scoped>
    /* .header-wrapper {
        background-color: red !important;
    } */

    .tours-section {
        padding-top: 4rem;
    }
</style>